<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods:{
    _isMobile() {
	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	 return flag;
}
  },
  mounted(){
    if (this._isMobile()) {
      // alert("手机端");
      // this.$router.replace('/mi');
      // this.$router.replace('http://192.168.0.119:8080')
      window.location.href ='https://app.dolinkhub.com'
    }
  }
}
</script>

<style lang="less" >
html {
  height:100%;
}
body {
  padding-right: 0px !important;
  height:100%;
  
}
#app{
  
  height:100%;
}
/deep/.el-popup-parent--hidden {
  padding-right: 0px !important;
}
// .size{
//   // width: 100vw;
//   // height: 100vh;
// }
// html,body{
//   .size;
//   overflow: hidden;
//   margin: 0;
//   padding: 0;
// }
// #app {
//   .size;
// }
</style>
