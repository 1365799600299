import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from 'vue/types/umd'
import HomeView from '../views/HomeView.vue'
// import dataCenter from '../views/dataCenter/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'wxLogin',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/wxLogin/index.vue')
  },
  {
    path: '/dataCenter',
    name: 'dataCenter',
    component: () => import(/* webpackChunkName: "dataCenter" */'../views/dataCenter/index')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import(/* webpackChunkName: "dataCenter" */'../views/business/index')
  },
  {
    path: '/integratedCenter',
    name: 'integratedCenter',
    component: () => import(/* webpackChunkName: "dataCenter" */'../views/integratedCenter/index')
  },
  // {
  //   path: '/HelloWorld',
  //   name: 'HelloWorld',
  //   component: () => import('../components/HelloWorld.vue')
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutOur/index.vue')
  },
  
  {
    path: '/wxLogin',
    name: 'wxLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/wxLogin/index.vue')
  },
  // 微信登录

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
