<template>
  <div class="diglog">
    <el-dialog
      width="930px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormdemo"
    >
      <div class="dislog-content">
        <div class="item">
          <img height="100%" src="../assets/images/banner/left.png" alt="" />
        </div>
        <div class="item">
          <div class="form-content">
            <div class="form-title">欢迎联系我们</div>
            <div class="form-desc">每一次尝试，都是超越</div>
            <div>
              <img
                style="width: 182px; height: 181px"
                src="../assets/images/ewm.png"
                alt=""
              />
            </div>
            <div class="addwx">扫码添加企业微信</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HomeForm',
  data () {
    return {
      dialogFormdemo: false
    }
  },
  methods: {},
  destroyed () {}
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding: 0;
}
.dislog-content {
  height: 560px;
  display: flex;
  .item:first-child {
    border-radius: 5%;
    width: 360px;
  }
  .item:last-child {
    width: 568px;
    height: 560px;
    background: #ffffff;
    display: flex;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex;
    justify-content: center;
    .form-content {
      width: 228px;
      text-align: center;
      .form-title {
        width: 228px;
        height: 48px;
        font-size: 34px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0f4acf;
        line-height: 48px;
        margin-top: 92px;
      }
      .form-desc {
        width: 228px;
        height: 46px;
        margin-bottom: 65px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 46px;
      }
      .addwx {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #374567;
        line-height: 23px;
        letter-spacing: 2px;
        margin-top: 16px;
      }
    }
  }
}
</style>
