<template>
  <div class="home">
    <div class="headerWrapper">
      <div class="header">
        <div class="container">
          <!-- <el-row :gutter="5" v-show="currentIndex !== 1"> -->
          <el-row class="header-elrow" :gutter="5">
            <el-col
              :span="4"
              @click.native="goHomeIndex"
              style="padding-left: 20px"
            >
              <img
                src="../assets/images/header/logo1.png"
                alt=""
                style="
                  width: 102px;
                  height: 25px;
                  margin-top: 15px;
                  cursor: pointer;
                "
              />
            </el-col>
            <el-col
              @click.native="checkTitleName('home')"
              :span="2"
              :class="active === 'home' ? 'hometitleactive' : 'hometitle'"
              class="pointer"
              >首页
            </el-col>
            <el-col
              @mouseenter.native="enter"
              @mouseleave.native="leave"
              :span="2"
              class="pointer"
              :class="active === 'product' ? 'hometitleactive' : 'hometitle'"
            >
              <div>
                产品 <i class="el-icon-arrow-up" v-if="showTip"></i
                ><i class="el-icon-arrow-down" v-else></i>
                <div class="productItem" v-if="showTip">
                  <div class="item-one" @click="goTo(1)">业务中台</div>
                  <div class="item-one" @click="goTo(2)">数据中台</div>
                  <div class="item-one" @click="goTo(3)">集成中台</div>
                </div>
              </div>
            </el-col>
            <el-col
              @click.native="checkTitleName('we')"
              :span="8"
              class="pointer"
              :class="active === 'we' ? 'hometitleactive' : 'hometitle'"
            >
              关于我们
            </el-col>
            <el-col :span="3" class="home-shop">
              <img
                src="../assets/images/header/tele.png"
                alt=""
                style="margin-right: 5px"
              />
              13305196015
            </el-col>
            <el-col :span="3" class="home-shop-item">
              <div class="item">
                <img src="../assets/images/header/shop.png" alt="" />
              </div>
              <div class="item" style="cursor: pointer" @click="goTowxLogin">
                应用访问
                <!-- @click="goToShop" -->
              </div>
            </el-col>
            <!-- @click.native="reserve" -->
            <el-col :span="2" class="home-reserve"
              @click.native="reserve"> 预约展示</el-col
            >
          </el-row>
          <el-row :gutter="5" v-show="currentIndex === 5">
            <el-col :span="4" style="padding-left: 20px">
              <img
                src="../assets/images/header/logobai.png"
                alt=""
                style="margin-top: 15px"
              />
            </el-col>
            <el-col
              @click.native="checkTitleName('home')"
              :span="2"
              class="pointer"
              style="color: #fff"
              >首页
            </el-col>
            <el-col
              @click.native="checkTitleName('product')"
              :span="2"
              class="pointer"
              style="color: #fff"
            >
              产品
            </el-col>
            <el-col
              @click.native="checkTitleName('we')"
              :span="8"
              class="pointer"
              style="color: #fff"
            >
              关于我们
            </el-col>
            <el-col :span="3" class="home-shop-item" style="color: #fff">
              <div class="item">
                <img src="../assets/images/header/tele1.png" alt="" />
              </div>
              <div class="item">400-2343-3948</div>
            </el-col>
            <el-col :span="3" class="home-shop-item">
              <div class="item">
                <img src="../assets/images/header/shop1.png" alt="" />
              </div>
              <div class="item" style="color: #fff">多链商城</div>
            </el-col>
               <!-- @click.native="reserve" -->
            <el-col
              :span="2"
              class="home-reserve"
              ><span>预约展示</span></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="main-cnt">
      <div class="page-component__scroll el-scrollbar">
        <div class="side-bar">
          <a href="#1" class="icon-tele">
            <img src="../assets/images/banner/dh.png" alt="" />
            <div class="tele-tips">
              <i></i>
              <div class="telt-title">联系电话</div>
              <div class="tele-num">13305196015</div>
            </div>
          </a>
          <a href="#1" class="icon-chat">
            <img src="../assets/images/banner/wx.png" alt="" />
            <div class="chat-tips">
              <i></i>
              <img src="../assets/images/wx.png" />
              <div class="wx-title">扫码添加企业微信</div>
            </div>
          </a>
          <a href="#" id="icon-jt" v-show="showBtn">
            <img src="../assets/images/banner/jt.png" alt="" />
          </a>
        </div>
        <div class="carousel-wrap" id="carousel">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in slideList" :key="index">
              <img
                :src="item.image"
                alt=""
                style="width: 100%; height: 448px"
              />
              <div class="content">
                <div class="item" v-show="index === 0">
                  <div class="title" data-swiper-parallax="-100">多链互联</div>
                  <div class="item-content" data-swiper-parallax="-200">
                    餐饮行业数字化转型软件服务商
                  </div>
                  <div class="btn cursor" @click="demoFn" data-swiper-parallax="-300" >体验Demo</div>
                </div>
                <div class="itemF" v-show="index === 1">
                  <div
                    class="title"
                    style="color: #fff"
                    data-swiper-parallax="-100"
                  >
                    数字化转型
                  </div>
                  <div
                    class="item-content"
                    style="color: #fff"
                    data-swiper-parallax="-200"
                  >
                    业务创新、提升企业核心竞争力，是餐饮企业的一道生存必答题
                  </div>
                  <div class="btn btnyw cursor"  @click="demoFn" data-swiper-parallax="-300" >体验Demo</div>
                </div>
                <div class="item" v-show="index === 2" style="margin-top:-12px">
                  <div class="title" style="margin-bottom:10px" data-swiper-parallax="-100">
                    数字化转型
                  </div>
                  <div
                    class="item-content"
                    style="width: 495px; height: 36px; letter-spacing: 4px;"
                    data-swiper-parallax="-200"
                  >
                   <p> 数字化转型让决策力提升——<span
                      style="color: #155bd4; letter-spacing: 4px;line-height: 30px;"
                      >更加确定</span
                    ></p><p>让餐饮企业收获极致的客户体验——<span
                      style="color: #155bd4; letter-spacing: 4px;line-height: 30px;"
                      >更懂客户</span
                    ></p><p>为客户餐饮企业带来绝对的效率提升——<span
                      style="color: #155bd4; letter-spacing: 4px;line-height: 30px;"
                      >更加高效</span
                    ></p>
                  </div>
                  <div class="btn cursor" style="margin-top:61px" @click="demoFn" data-swiper-parallax="-300" >体验Demo</div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="item-three">
          <div class="item-three-item">
            <div><img src="../assets/images/item-three/one.png" alt="" /></div>
            <div style="margin-left: 24px">专注餐饮十年</div>
          </div>
          <div class="item-three-item">
            <div><img src="../assets/images/item-three/two.png" alt="" /></div>
            <div style="margin-left: 24px">技术开放前瞻</div>
          </div>
          <div class="item-three-item">
            <div>
              <img src="../assets/images/item-three/three.png" alt="" />
            </div>
            <div style="margin-left: 24px">产品开箱即用</div>
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 60px">
          <img
            src="../assets/images/content-one.png"
            style="width: 1120px; height: 443px"
            alt=""
          />
        </div>
        <div class="header-title">多链产品--助力餐企数字化转型</div>
        <div class="ywzt">
          <div class="ywzt-item ywzt-img">
            <img style="height:340px" src="../assets/images/content/one.png" alt="" />
          </div>
          <div class="ywzt-item">
            <div class="title">业务中台</div>
            <div class="msg">快速支持业务流程与商业模式创新，提升客户体验、提高客户价值，助力企业降本增效</div>
            <div class="more" @click="goTo(1)">了解更多</div>
          </div>
        </div>
        <div class="sjzt">
          <div class="sjzt-item">
            <div class="title">数据中台</div>
            <div class="msg">全渠道数据配置化接入，打通数据壁垒，沉淀企业数据资产。场景式行业分析模版，智能预测与决策，数字指导企业行动</div>
            <div class="more" @click="goTo(2)">了解更多</div>
          </div>
          <div class="sjzt-item">
            <img style="height:400px" src="../assets/images/content/two.png" alt="" />
          </div>
        </div>
        <div class="jczt">
          <div class="jczt-item">
            <img style="height:340px"  src="../assets/images/content/three.png" alt="" />
          </div>
          <div class="jczt-item">
            <div class="title">集成中台</div>
            <div class="msg">行业应用软件，一键集成，建立行业数据标准</div>
            <div class="more" @click="goTo(3)">了解更多</div>
          </div>
        </div>
        <div class="hzhb">
          <div class="title">合作伙伴</div>
          <div class="content">
            <div class="item">
              <img src="../assets/images/cooperation/kj.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/images/cooperation/jdx.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/images/cooperation/xcn.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/images/cooperation/hsdpj.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/images/cooperation/mzdp.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/images/cooperation/xjd.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/images/cooperation/yh.png" alt="" />
            </div>
            <div class="item">
              <img src="../assets/images/cooperation/ypc.png" alt="" />
            </div>
          </div>
        </div>
        <div class="ddyfw">迭代与服务</div>
        <div class="fwList">
          <div class="item">
            <div class="img">
              <img src="../assets/images/server/one.png" alt="" />
            </div>
            <div style="margin-left: 24px">定期版本迭代更新</div>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/server/two.png" alt="" />
            </div>
            <div style="margin-left: 24px">不定期客户回访</div>
          </div>
          <div class="item">
            <div class="img">
              <img src="../assets/images/server/three.png" alt="" />
            </div>
            <div style="margin-left: 24px">7x24小时服务支持</div>
          </div>
        </div>
        <div class="fw"></div>
        <div class="footer">
          <div class="footer-item">
            <div class="item">
              <div class="title">首页</div>
              <div class="boder"></div>
              <div
                class="item-item"
                style="cursor: pointer"
                @click="checkTitleName('home')"
              >
                首页
              </div>
            </div>
            <div class="item">
              <div class="title">产品</div>
              <div class="boder"></div>
              <div class="item-item" style="cursor: pointer" @click="goTo(1)">
                业务中台
              </div>
              <div
                class="item-item"
                style="margin: 16px 0; cursor: pointer"
                @click="goTo(2)"
              >
                数据中台
              </div>
              <div class="item-item" style="cursor: pointer" @click="goTo(3)">
                集成中台
              </div>
            </div>
            <div class="item">
              <div class="title">关于我们</div>
              <div class="boder"></div>
              <div
                class="item-item"
                style="cursor: pointer"
                @click="checkTitleName('we')"
              >
                关于我们
              </div>
            </div>
            <div class="item">
              <div class="title">联系我们</div>
              <div class="boder"></div>
              <div class="item-item">13305196015</div>
            </div>
            <div class="item">
              <div class="title">邮箱</div>
              <div class="boder"></div>
              <div class="item-item">sales@dolinkhub.com</div>
            </div>
            <div class="item">
              <div class="title">企业微信</div>
              <div class="boder"></div>
              <div>
                <img src="../assets/images/wx.png" alt="" />
              </div>
            </div>
          </div>
          <div class="footer-msg">
            <span style="margin-right: 32px"
              >Copyright @ 2021-2037 多链互联信息科技南京有限公司</span
            >
            <!-- <span>隐私政策  </span>
            <span style="margin: 0 8px;">| </span>
            <span style="margin-right: 32px;">友情链接</span> -->
            <span style="cursor:pointer" @click="gotoBA">苏ICP备2021052264号-1</span>
          </div>
        </div>
      </div>
    </div>
    <edit-form ref="editForm"></edit-form>
    <demo ref="demo"></demo>
  </div>
</template>

<script>
import editForm from '@/components/form.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Demo from '@/components/demo.vue'
import axios from 'axios'
export default {
  name: 'HomeView',
  components: { editForm, swiper, swiperSlide, Demo },
  data () {
    return {
      baseUrl:'',
      active: 'home',
      bgcNum: 1,
      slideList: [
        {
          clickUrl: '#',
          desc: 'nhwc',
          // image: require('../assets/images/banner/four.gif')
        },
        {
          clickUrl: '#',
          desc: 'nhwc',
          // image: require('../assets/images/banner/two.gif')
        },
        {
          clickUrl: '#',
          desc: 'nhwc',
          // image: require('../assets/images/banner/three.gif')
        }
      ],
      currentIndex: 0,
      timer: '',
      showTip: false,
      showBtn: false,
      swiperOption: {
        effect: 'fade',
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },
        speed: 1000,
        loop: true,
        pagination: {
          el: '.swiper-pagination', // 分页器
          clickable: true,
          disableOnInteraction: false
        }
      }
    }
  },
  created () {
    document.documentElement.scrollTop = 0
    this.$nextTick(() => {
      this.timer = setInterval(() => {
        this.autoPlay()
      }, 10000)
    })
    // const url = window.location.href
    // const code = url.match(/=(\S*)&state=#/)
    // if (code !== null) {
    //   // 传递code 给后端
    //   this.sendCode(code)
    //   // 如果能获得code，说明需要处理url并跳转，如果不能获得code，不用处理
    //   // const replaceUrl = 'https://' + window.location.host + '/#/wxLogin/?code=' + code[1] + '&state='
    //   // return location.replace(replaceUrl)
    // }
  },
  mounted () {
    // this.$refs.editForm.dialogFormVisible = true
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
      // 获取微信登录用户信息 

      sendCode(code) {
        
    this.baseUrl = 'https://portal-saas.dolinkhub.com'
      console.log(code, '33')
      axios.get(`${this.baseUrl}/globalSso/user/pcQrCodeLogin?loginCode=${code[1]}`).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.url == null) {
            localStorage.setItem('userInfo', JSON.stringify(res.data.data))
            this.$router.push({path:'/wxLogin',query:{activeKey :1}})
          } 
          else {
            window.open(res.data.data.url,'_self')
          }
        }
        // this.code = res.data.data.code
      })
    },
    // 去备案
    gotoBA () {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    // 体验Demo 弹框打开
    demoFn () {
      this.$refs.demo.dialogFormdemo = true
    },
    // 回到首页
    goHomeIndex () {
      this.$router.push('/')
    },
    // 微信登录
    goTowxLogin(){
      this.$router.push('/wxLogin')
    },
    // 跳转
    goToShop () {
      window.open('http://bss.dolinkhub.com/user/login')
    },
    // 预约展示
    reserve () {
      console.log('预约展示')
      this.$refs.editForm.dialogFormVisible = true
    },
    handleScroll () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 48) {
        this.showTip = false
        console.log(scrollTop, '11111')
      }
      if (scrollTop > 900) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
    },
    enter () {
      this.showTip = true
    },
    leave () {
      this.showTip = false
    },
    checkTitleName (data) {
      console.log(data)
      this.active = data
      // if (data === 'product') {
      //   this.showTip = !this.showTip
      // }
      if (data === 'we') {
        this.$router.push('/about')
      }
    },
    goTo (data) {
      this.active = 'product'
      if (data === 1) {
        this.$router.push('/business')
      }
      if (data === 2) {
        this.$router.push('/dataCenter')
      }
      if (data === 3) {
        this.$router.push('/integratedCenter')
      }
    },
    changeBgc (data) {
      console.log(data)
      this.bgcNum = data
    },
    go () {
      this.timer = setInterval(() => {
        this.autoPlay()
      }, 10000)
    },
    stop () {
      clearInterval(this.timer)
      this.timer = null
    },
    change (index) {
      this.currentIndex = index
    },
    autoPlay () {
      this.currentIndex++
      if (this.currentIndex > this.slideList.length - 1) {
        this.currentIndex = 0
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
    window.removeEventListener('scroll', this.handleScroll)
    this.timer = null
  }
}
</script>
<style lang="less" scoped>
// cursor
.cursor {
  cursor: pointer;
}
.header-elrow {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
/deep/ .swiper-pagination-bullets {
  bottom: 94px;
  left: -484px;
  width: 100%;
}
/deep/ .swiper-pagination-bullet {
  width: 26px;
  height: 4px;
  display: inline-block;
  border-radius: 4px;
  background: #155bd4;
  // opacity: 0.2;
}
.home {
  p {
    padding: 0;
    margin: 0;
  }
  position: relative;
  background: #f2f2f2;

  .headerWrapper {
    // background: #F2F2F2;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1500;
    height: 48px;
    .header {
      height: 48px;
      color: #fff;
      top: 0;
      left: 0;
      width: 100%;
      line-height: 48px;
      z-index: 100;
      position: relative;
      .container {
        height: 100%;
        box-sizing: border-box;
        font-size: 15px;
        color: black;
        width: 1200px;
        padding: 0;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0px 2px 20px 0px rgba(207, 207, 208, 0.26);
        .pointer {
          cursor: pointer;
        }
        .productItem {
          // display: none;
          width: 104px;
          height: 152px;
          background: #ffffff;
          box-shadow: 0px 2px 20px 0px rgba(209, 215, 220, 0.26);
          border-radius: 4px;
          text-align: center;
          color: #323233;
          margin-left: -30px;
          .item-one:hover {
            color: #155bd4;
          }
        }
        .hometitleactive {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #155bd4;
        }
        .hometitle {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
        }
        .home-shop {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #323233;
        }
        .home-shop-item {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #323233;
          line-height: 48px;
          display: flex;
          .item:first-child {
            margin-top: 7px;
          }
        }
        .home-reserve {
          background: #155bd4;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          height: 49px;
          cursor: pointer;
        }
      }
    }
  }
  .main-cnt {
    width: 1200px;
    padding: 0;
    margin: 0 auto;
    height: 100%;
    min-height: auto;
    background: #fff;
    .page-component__scroll {
      // height: calc(100% - 48px);
      // margin-top: 48px;
    }
    .el-scrollbar {
      overflow: hidden;
      // position: relative;
    }
    .msg-item {
      position: fixed;
      top: 500px;
      right: 0;
      width: 60px;
      height: 143px;
      background: linear-gradient(
        90deg,
        #639dff 0%,
        #165dd5 100%,
        #155bd4 100%
      );
      .item:first-child {
        .left {
          right: 100px;
        }
      }
    }
    .side-bar a,
    .chat-tips i {
      background: linear-gradient(
        90deg,
        #639dff 0%,
        #165dd5 100%,
        #155bd4 100%
      );
    }
    .side-bar {
      width: 66px;
      position: fixed;
      bottom: 200px;
      right: 25px;
      font-size: 0;
      line-height: 0;
      z-index: 100;
    }
    .side-bar a {
      width: 66px;
      height: 66px;
      border-radius: 4px;
      text-align: center;
      padding-top: 20px;
      box-sizing: border-box;
      display: inline-block;
      background-color: #ddd;
      margin-bottom: 2px;
    }
    .side-bar a:hover {
      background-color: #669fdd;
    }
    .side-bar .icon-qq {
      background-position: 0 -62px;
    }
    .side-bar .icon-chat {
      background-position: 0 -130px;
      position: relative;
    }
    .wx-title {
      width: 128px;
      height: 23px;
      margin: 10px auto 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #374567;
      line-height: 23px;
      letter-spacing: 2px;
    }
    .side-bar .icon-chat:hover .chat-tips {
      display: block;
    }
    .side-bar .icon-tele:hover .tele-tips {
      display: block;
    }
    .tele-tips {
      width: 180px;
      height: 99px;
      background: #ffffff;
      box-shadow: 0px 2px 20px 0px rgba(175, 176, 176, 0.26);
      border: 1px solid #ebedf0;
      text-align: left;
      position: absolute;
      right: 78px;
      top: -17px;
      display: none;
      .telt-title {
        width: 56px;
        height: 20px;
        margin: 24px 0 0 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323233;
        line-height: 20px;
      }
      .tele-num {
        width: 141px;
        height: 23px;
        margin: 7px 0 0 16px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #155bd4;
        line-height: 23px;
      }
      i {
        position: absolute;
        right: -9px;
        top: 41px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #fff;
      }
    }
    .chat-tips {
      width: 180px;
      height: 201px;
      box-sizing: border-box;
      padding-top: 15px;
      background: #ffffff;
      box-shadow: 0px 2px 20px 0px rgba(175, 176, 176, 0.26);
      border: 1px solid #ebedf0;
      text-align: center;
      position: absolute;
      right: 78px;
      top: -55px;
      display: none;
    }
    .chat-tips i {
      display: inline-block;
      position: absolute;
      right: -9px;
      top: 80px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #fff;
    }
    .chat-tips img {
      width: 120px;
      height: 120px;
    }

    #icon-jt {
      width: 66px;
      height: 40px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #155bd4;
      padding-top: 5px;
      margin-top: 20px;
    }

    .carousel-wrap {
      height: 448px;
      width: 100%;
      overflow: hidden;
      background-color: #fff;
      margin-top: 48px;
      .slide-ul {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
        li {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      .img-items {
        position: absolute;
        top: 140px;
        left: 64px;
        color: #000;
      }
      .carousel-items {
        position: absolute;
        z-index: 10;
        top: 380px;
        left: 80px;
        width: 100%;
        margin: 0 auto;
        // text-align: center;
        font-size: 0;
      }
      .content {
        position: absolute;
        top: 140px;
        left: 64px;
        color: #000;
        .btn {
          margin-top: 40px;
          width: 120px;
          height: 40px;
          background: linear-gradient(
            90deg,
            #155bd4 0%,
            #165dd5 23%,
            #2f94ec 100%
          );
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
        }
        .itemF {
          color: #fff;
        }
        .btnyw{
            color: #fff;
            background: #2F94EC;
        }
        .title {
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #323233;
          letter-spacing: 4px;
          margin-bottom: 24px;
        }
        .item-content {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #374567;
          letter-spacing: 2px;
        }
        .content-item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #323233;
        }
      }
      .carousel-items span {
        display: inline-block;
        width: 26px;
        height: 4px;
        margin: 0 3px;
        border-radius: 4px;
        background-color: #b2b2b2;
        cursor: pointer;
      }
      .carousel-items .active {
        background: #155bd4;
      }

      .list-enter-to {
        transition: all 1s ease;
        transform: translateX(0);
      }

      .list-leave-active {
        transition: all 1s ease;
        transform: translateX(-100%);
      }

      .list-enter {
        transform: translateX(100%);
      }

      .list-leave {
        transform: translateX(0);
      }
    }
    .item-three {
      width: 1120px;
      height: 120px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 2px 20px 0px rgba(207, 207, 208, 0.26);
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      display: flex;
      position: relative;
      top: -60px;
      left: 40px;
      z-index: 9;
      .item-three-item {
        width: 33.33%;
        height: 120px;
        line-height: 120px;
        display: flex;
        justify-content: center;
      }
      img {
        margin-top: 28px;
      }
      // .item-three-item:hover{
      //   font-size: 18px;
      // }
      // .item-three-item:hover img{
      //   -webkit-transition:0.4s linear;
      //   transition:0.4s ease-in;
      //   -webkit-transform:rotate(360deg);
      //   transform:rotate(360deg);
      // }
    }
    .header-title {
      width: 342px;
      height: 22px;
      margin: 10px auto 60px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #323233;
      -webkit-transition-delay: 0.3s;
      transition-delay: 0.3s;
    }
    .ywzt {
      width: 1200px;
      height: 400px;
      box-sizing: content-box;
      display: flex;
      background: #f5fafe;
      .ywzt-item:first-child {
        box-sizing: content-box;
        width: 600px;
        height: 400px;
        text-align: center;
        margin-left: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ywzt-item:last-child {
        .title {
          margin: 118px 439px 0 0;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323233;
        }
        .msg {
          width: 386px;
          height: 46px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #374567;
          letter-spacing: 4px;
          line-height: 23px;
          margin: 11px 0 32px 0;
        }
        .more {
          width: 120px;
          height: 40px;
          background: linear-gradient(
            90deg,
            #155bd4 0%,
            #165dd5 23%,
            #2f94ec 100%
          );
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    // .ywzt:hover img{
    //   transform:scale(1.1);
    // }
    .sjzt {
      width: 1200px;
      height: 400px;
      display: flex;
      padding: 1px;
      .sjzt-item:first-child {
        margin-right: 72px;
        .title {
          margin: 118px 0 14px 164px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323233;
        }
        .msg {
          width: 386px;
          height: 46px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #374567;
          letter-spacing: 4px;
          line-height: 23px;
          padding: 0px 0 32px 164px;
        }
        .more {
          width: 120px;
          height: 40px;
          background: linear-gradient(
            90deg,
            #155bd4 0%,
            #165dd5 23%,
            #2f94ec 100%
          );
          border-radius: 4px;
          margin-left: 164px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          margin-top: 32px;
        }
      }
      .sjzt-item:last-child {
        box-sizing: content-box;
        width: 500px;
        height: 400px;
        text-align: center;
        line-height: 700px;
      }
    }
    // .sjzt:hover img{
    //   transform:scale(1.1);
    // }
    .jczt {
      width: 1200px;
      height: 400px;
      background: #f5fafe;
      display: flex;
      .jczt-item:first-child {
        box-sizing: content-box;
        width: 600px;
        height: 400px;
        text-align: center;
        line-height: 707px;
        margin-left: 55px;
      }
      .jczt-item:last-child {
        .title {
          margin: 118px 439px 0 0;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323233;
        }
        .msg {
          width: 386px;
          height: 46px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #374567;
          letter-spacing: 4px;
          line-height: 46px;
          margin: 11px 0 32px 0;
        }
        .more {
          width: 120px;
          height: 40px;
          background: linear-gradient(
            90deg,
            #155bd4 0%,
            #165dd5 23%,
            #2f94ec 100%
          );
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    // .jczt:hover img{
    //   transform:scale(1.1);
    // }
    .hzhb {
      width: 1200px;
      height: 366px;
      padding: 1px;
      .title {
        width: 96px;
        height: 22px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #323233;
        line-height: 22px;
        margin: 64px auto 64px;
      }
      .content {
        padding: 25px 24px 24px 28px;
        // background: #f5fafe;
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 260px;
          height: 96px;
          background: #ffffff;
          border-radius: 4px;
        }
        // .item:hover{
        //   box-shadow: 0px 2px 20px 20px rgba(209, 215, 220, 0.66);
        // }
        // .item:hover img{
        //   transform:scale(1.1);
        // }
        .item:nth-child(2) {
          margin: 0 30px 30px 30px;
        }
        .item:nth-child(3) {
          margin: 0 33px 30px 0;
        }
        .item:nth-child(6) {
          margin: 0 30px 0 30px;
        }
        .item:nth-child(7) {
          margin: 0 30px 0 0;
        }
      }
    }
    .ddyfw {
      width: 120px;
      height: 22px;
      margin: 64px auto 24px;
      padding-top: 37px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #323233;
      line-height: 22px;
    }
    .fwList {
      width: 1200px;
      height: 140px;
      padding: 17px 30px;
      background: #ffffff;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #323233;
      .item {
        display: flex;
        justify-content: center;
        line-height: 108px;
        width: 358px;
        height: 108px;
        background: #ffffff;
        box-shadow: 0px 4px 20px 0px rgba(207, 207, 207, 0.26);
        .img {
          margin-top: 20px;
        }
      }
      // .item:hover{
      //   font-size: 18px;
      // }
      // .item:hover img{
      //   -webkit-transition:0.4s linear;
      //   transition:0.4s ease-in;
      //   -webkit-transform:rotate(360deg);
      //   transform:rotate(360deg);
      // }
      .item:nth-child(2) {
        margin-right: 32px;
        margin-left: 32px;
      }
    }
    .fw {
      width: 1199px;
      height: 300px;
      background-image: url("../assets/images/header/footer.png");
      background-size: 100% 100%;
    }
    .footer {
      width: 1200px;
      height: 233px;
      background: #fff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3d485d;
      .footer-item {
        height: 180px;
        padding: 0 80px;
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        .item .title {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #323233;
        }
        .item:first-child {
          width: 38px;
          .boder {
            margin: 16px 0;
            width: 27px;
            height: 1px;
            background: #d8d8d8;
          }
        }
        .item:nth-child(2) {
          width: 64px;
          .boder {
            margin: 16px 0;
            width: 64px;
            height: 1px;
            background: #d8d8d8;
          }
        }
        .item:nth-child(3) {
          width: 64px;
          .boder {
            margin: 16px 0;
            width: 64px;
            height: 1px;
            background: #d8d8d8;
          }
        }
        .item:nth-child(4) {
          width: 112px;
          .boder {
            margin: 16px 0;
            width: 93px;
            height: 1px;
            background: #d8d8d8;
          }
        }
        .item:nth-child(5) {
          width: 160px;
          .boder {
            margin: 16px 0;
            width: 150px;
            height: 1px;
            background: #d8d8d8;
          }
        }
        .item:nth-child(6) {
          width: 78px;
          margin-left: 120px;
          .boder {
            margin: 16px 0;
            width: 78px;
            height: 1px;
            background: #d8d8d8;
          }
        }
      }
      .footer-msg {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3d485d;
        border-top: 1px solid #d2d7df;
        height: 52px;
        line-height: 52px;
        text-align: center;
      }
    }
  }
}
</style>
