import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.less'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'animate.css/animate.min.css' // 引入 animate.css
import axios from 'axios'
// import VueLazyLoad from 'vue-lazyload' // 导入懒加载
// Vue.use(VueLazyLoad)

Vue.prototype.$axios = axios;
Vue.use(ElementUI)
Vue.config.productionTip = false
ElementUI.Dialog.props.lockScroll.default = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
