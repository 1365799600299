<template>
  <div class="diglog">
    <el-dialog
      width="930px"
      :close-on-click-modal="false"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
    >
      <div class="dislog-content">
        <div class="item">
          <img height="100%" :src="psdimg" alt="" />
        </div>
        <div class="item">
          <div class="form-content">
            <div class="form-title">我们珍惜与您的每一次沟通</div>
            <div class="form-desc">
              留下您的联系方式,我们会在1个工作日内与您联系!
            </div>
            <div class="form-item">
              <el-form :model="form" ref="ruleForm">
                <el-form-item>
                  <el-input
                    v-model="form.mobile"
                    autocomplete="off"
                    placeholder="请输入您的手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <!-- <el-input v-model="form.name" autocomplete="off"> -->
                  <div class="bind_code">
                    <el-input
                      class="bind_code_input code"
                      v-model="form.code"
                      type="text"
                      placeholder="手机验证码"
                    />
                    <el-button
                      @click.native="countDown"
                      :disabled="disabled"
                      class="codebtn"
                      >{{ btnText }}</el-button
                    >
                  </div>
                  <!-- </el-input> -->
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="form.name"
                    autocomplete="off"
                    placeholder="请输入您的姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="form.companyName"
                    autocomplete="off"
                    placeholder="请输入您的公司名称"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="form.remark"
                    autocomplete="off"
                    placeholder="请输入您的要咨询的内容"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    @click="submitForm('ruleForm')"
                    type="primary"
                    style="width: 100%; background: #155bd4"
                    >提交</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HomeForm',
  data () {
    return {
      psdimg: require('@/assets/images/banner/left.png'),
      dialogFormVisible: false,
      form: {
      },
      btnText: '获取验证码',
      disabled: false,
      clock: null,
      totalTime: 60,
      canClick: true, // 添加canClick
      code: ''
    }
  },
  methods: {
    // 获取验证码
    countDown () {
      console.log(222)
      if (this.form.mobile === '') {
        this.$message.error('请输入手机号')
        return false
      }

      if (!this.disabled) {
        axios.post(`https://portal.dolinkhub.com/sso/mailSMS/sendSMSCode?mobile=${this.form.mobile}`).then((res) => {
          this.code = res.data.data.code
        })
        console.log('获取验证码')
        this.disabled = true
        this.btnText = `${this.totalTime}s`
        // api.iforgotSms({ mobile: this.mobile }).then(() => {})
        this.clock = window.setInterval(() => {
          this.totalTime -= 1
          this.btnText = `${this.totalTime}s`
          if (this.totalTime < 0) {
            window.clearInterval(this.clock)
            this.btnText = '获取验证码'
            this.totalTime = 60
            this.disabled = false
          }
        }, 1000)
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form.code, this.code)
          if (this.form.code !== this.code) {
            this.$message.error('验证码有误')
            return false
          }
          axios.post('https://portal.dolinkhub.com/sso/netMsg/add', this.form).then((res) => {
            this.$message.success(res.data.message)
            this.dialogFormVisible = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  },
  destroyed () {
    window.clearInterval(this.clock)
    this.clock = null
  }
}
</script>
<style lang="less" scoped>
/deep/.el-button--primary {
  border: none;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding: 0;
}
.dislog-content {
  height: 560px;
  display: flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex;
  .item:first-child {
    border-radius: 5%;
    width: 360px;
  }
  .item:last-child {
    flex: 1;
    height: 560px;
    background: #ffffff;
    display: flex;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    .form-content {
      .form-title {
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323233;
        line-height: 37px;
      }
      .form-desc {
        height: 20px;
        margin-top: 16px;
        margin-bottom: 48px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #969799;
        line-height: 20px;
      }
      .form-item {
        width: 360px;
        position: relative;
        .bind_code {
          width: 279.5px;
          height: 35px;
          position: relative;
        }
        .codebtn {
          position: absolute;
          // top: 15px;
          right: -79px;
          font-size: 11px;
          font-weight: 500;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #1b3dd1;
          line-height: 15px;
          cursor: pointer;
          padding-left: 18px;
          // border: 0;
        }
      }
    }
  }
}
</style>
